<template lang="pug">
v-app
  v-app-bar.pr-0(
    app,
    dark,
    Zdense,
    flat,
    Zhide-on-scroll,
    clipped-left,
    clipped-right,
    Zcolor="appBarColor",
    color="#373740",
    Zextended,
    height="50",
    extension-height="37"
  )
    template(v-slot:extension, v-if="!!$scopedSlots['header-extension']")
      slot(name="header-extension")

    slot(name="header-layout")
      v-app-bar-nav-icon(:ripple="false", @click.stop="setDrawer(!drawer)")
      v-progress-linear(
        :active="loading",
        :indeterminate="loading",
        absolute,
        bottom
      )
      slot(name="header")
        v-spacer

        v-toolbar-title.cash-register-name.mx-2.align-center(
          v-if="!$vuetify.breakpoint.lgAndUp"
        )
          slot(name="title")
            span {{ cashRegister.name ? cashRegister.name : organization.businessName }}

        v-spacer

        v-btn#account_button(icon)
          v-icon mdi-account
          v-menu(bottom, left, activator="#account_button")
            v-list
              v-list-item-group
                v-list-item(
                  to="/profile",
                  :disabled="!user._links.createCashRegister"
                )
                  v-list-item-content
                    v-list-item-title {{ user.phone | phone }}
                    v-list-item-subtitle(v-if="user.name") {{ user.name }}
                  v-list-item-icon
                    v-icon mdi-account-box-outline
                v-list-item(@click="signOutConfirm()")
                  v-list-item-content
                    v-list-item-title {{ $t('выход') }}
                  v-list-item-icon
                    v-icon mdi-exit-to-app

  DrawerLeft(v-model="drawer")

  v-main
    slot
    //- v-container
      slot

  //- v-main
    v-container.align-start.pa-0(fluid fill-height)
      .d-flex.flex-grow-1.fill-height
        slot

  v-footer.pa-2(
    v-if="$scopedSlots.footer",
    app,
    Zabsolute,
    inset,
    bottom,
    padless,
    Zoutlined,
    Zfixed,
    Zcolor="transparent",
    color="#373740",
    width="100%",
    Zstyle="z-index: 500;",
    :class="{ 'pb-2': $vuetify.breakpoint.mobile }"
  )
    slot(name="footer")

  slot(name="rightdrawer")
  slot(name="dialogs")
</template>
<script>

// import DrawerLeft2 from 'T@/AppLayout.vue'
// import DrawerLeft from 'T@/components/DrawerLeft.vue'
import { mapActions, mapState } from 'vuex'
import DrawerLeft from 'T@/components/DrawerLeft.vue'

export default {
  components: {
    DrawerLeft,
  },

  data: () => ({
    appBarColor: null,
    loading: false,
  }),

  computed: {
    ...mapState({
      cashRegister: state => state.tjtheme.cashRegister,
      organization: state => state.tjtheme.organization,
      user: state => state.tjtheme.user,
      printer: state => state.printer.printer,
      drawer: state => state.tools.drawer,
      appInitialized: state => state.tools.appInitialized,
      cordovaBuildVersion: state => state.tools.cordovaBuildVersion,
    }),
  },
  created() {
    // this.loading = true
    // this.selectCashRegister(this.cashRegister.id).then(() => {
    //   this.loading = false
    //   if (this.cashRegister.fdoMode === 'OFFLINE' && this.hoursLeftTillBlocked(this.cashRegister) < 0) {
    //     this.cashRegister.fdoMode = 'BLOCKED'
    //   }
    // }).catch(() => {
    //   this.loading = false
    // })

    // // Last phone number part hint
    // try {
    //   localStorage.setItem('rekassa.kz-auth-last-phone-number-part', this.user.phone.slice(this.user.phone.length - 4))
    // } catch (error) {
    //   // console.error(error)
    // }

    // // Firebase Analytics setUserId
    // this.firebaseAnalyticsSetUserId(this.user.phone)

    // // HelpCrunch initialization
    // if (!this.appInitialized) {
    //   if (!this.$isCordova()) {
    //     window.HelpCrunch('onNewUnreadChat', (data) => {
    //       this.unreadCount = data.unreadChats
    //     })

    //     window.HelpCrunch('onChatClose', () => {
    //       window.HelpCrunch('hideChatWidget')
    //     })

    //     window.HelpCrunch('userAuth', { email: this.user.phone, user_id: this.user.phone })
    //   } else {
    //     window.cordova.plugins.HelpCrunchPlugin.initialize({
    //       organization: process.env.VUE_APP_HELPCRUNCH_ORGANIZATION,
    //       applicationId: process.env.VUE_APP_HELPCRUNCH_APP_ID,
    //       applicationSecret: process.env.VUE_APP_HELPCRUNCH_APP_SECRET,
    //     })

    //     window.cordova.plugins.HelpCrunchPlugin.updateUser(() => {}, () => {
    //       // console.error(error)
    //     }, {
    //       userId: this.user.phone,
    //     })

    //     window.addEventListener('onNewUnreadChat', (event) => {
    //       this.unreadCount = event.count
    //     }, false)
    //   }

    //   this.setAppInitialized(true)
    // } else if (this.$isCordova()) {
    //   window.cordova.plugins.HelpCrunchPlugin.getUnreadCount((count) => {
    //     this.unreadCount = count
    //   }, () => {
    //     // console.error(error)
    //   })
    // }

    // // Cashregister statusbar
    // if (!this.$vuetify.breakpoint.smAndUp && this.cashRegister.status === 'REGISTERED') {
    //   if (this.cashRegisterMode === 'OFFLINE') this.appBarColor = 'warning'
    //   else if (this.cashRegisterMode === 'BLOCKED') this.appBarColor = 'error'
    //   else this.appBarColor = null
    // }

    // // App rate
    // if (this.$isCordova()) {
    //   const appLaunchCount = parseInt(localStorage.getItem('rekassa.kz-tools-appLaunchCount') || 0, 10)
    //   const appRated = (localStorage.getItem('rekassa.kz-tools-appRated') || 'false') === 'true'
    //   if (!appRated && appLaunchCount > 30) {
    //     setTimeout(() => {
    //       localStorage.setItem('rekassa.kz-tools-appRated', 'true')
    //       window.cordova.plugins.AppReview.requestReview()
    //     }, 2000)
    //   }
    // }

    // // Check if Google or Face/Touch ID avaliable
    // if (this.$isCordova()) {
    //   if (this.$isAndroid()) {
    //     setTimeout(() => this.isGoogleAccountLinkAvailable(), 2000)
    //   } else {
    //     setTimeout(() => this.isFaceTouchIdAvailable(), 2000)
    //   }
    // }
  },

  methods: {
    ...mapActions({
      signOut: 'auth/signOut',
      fetchShiftByNumber: 'cashRegisters/fetchShiftByNumber',
      selectCashRegister: 'cashRegisters/selectCashRegister',
      fetchUserCashRegisterList: 'cashRegisters/fetchUserCashRegisterList',
      fetchUserCashRegisterDeregisteredList: 'cashRegisters/fetchUserCashRegisterDeregisteredList',
      createNewCashRegister: 'cashRegisters/createNewCashRegister',
      deleteNewCashRegister: 'cashRegisters/deleteNewCashRegister',
      showSnackbar: 'tools/showSnackbar',
      showConfirm: 'tools/showConfirm',
      setDrawer: 'tools/setDrawer',
      setAppInitialized: 'tools/setAppInitialized',
      firebaseAnalyticsSetUserId: 'tools/firebaseAnalyticsSetUserId',
    }),

    signOutConfirm() {
    },
    showChat() {
      if (!this.$isCordova()) {
        window.HelpCrunch('showChatWidget')
        window.HelpCrunch('openChat')
      } else {
        window.cordova.plugins.HelpCrunchPlugin.showChatScreen(() => {}, () => {
          // console.error(error)
        })
      }
      if (this.$vuetify.breakpoint.mdAndDown) this.setDrawer(false)
    },
    cashRegisterLoading() {

    },

  },

  beforeRouteLeave(to, from, next) {
    if (this.$vuetify.breakpoint.mdAndDown) this.setDrawer(false)
    next()
  },
}
</script>

<style lang="stylus">
// .v-toolbar__content, .v-toolbar__extension {
//   border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
// }

.v-sheet.v-footer.isMobile {
  border-radius: 0 0 30px 30px;
  overflow: hidden;
  background-color: #f00 !important;
}
</style>
